<div class="flex flex-col">
  <div class="flex flex-row items-center justify-between">
    <span class="example-snack-bar-message">
      {{ data.message }}
    </span>
    <span class="text-blue-300">
      {{ data.action }}
    </span>
  </div>
  <mat-progress-bar class="mt-2" mode="indeterminate"></mat-progress-bar>
</div>
