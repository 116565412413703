import { Injectable, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackBarProgressComponent } from './components/snack-bar-progress/snack-bar-progress.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  isSnackbarProgressOpen = signal(false);

  constructor(private snackBar: MatSnackBar) {}

  showSnackbar(message: string, action: string, duration: number) {
    return this.snackBar.open(message, action, {
      duration: duration,
    });
  }
  showSnackbarProgress(message: string, action: string) {
    return this.snackBar.openFromComponent(SnackBarProgressComponent, {
      data: { message, action, isSnackbarProgressOpen: this.isSnackbarProgressOpen },
    });
  }

  dismissSnackbarProgress() {
    this.isSnackbarProgressOpen.set(false);
  }
}
