import { Component, Inject } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-progress',
  imports: [MatProgressBarModule],
  templateUrl: './snack-bar-progress.component.html',
  styleUrl: './snack-bar-progress.component.scss',
})
export class SnackBarProgressComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<SnackBarProgressComponent>,
  ) {
    if (data.isSnackbarProgressOpen) {
      this.dismissWithAction();
    }
  }

  dismissWithAction() {
    this.snackBarRef.dismissWithAction();
  }
}
