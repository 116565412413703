import { tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private http: HttpClient) {}

  setAccessToken(token: string): void {
    localStorage.setItem('token', token);
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem('refresh', refreshToken);
  }

  getAccessToken(): string {
    return localStorage.getItem('token') || '';
  }

  getRefreshToken(): string {
    return localStorage.getItem('refresh') || '';
  }

  getTokenExpiration(token: string): number | null {
    if (!token) {
      return null;
    }

    try {
      // Split the token into its three parts: header, payload, signature
      const payload = token.split('.')[1];
      if (!payload) {
        return null;
      }

      // Decode the Base64 URL encoded string (handle padding and character replacements)
      const base64Url = payload.replace(/-/g, '+').replace(/_/g, '/');
      const base64 = base64Url + '='.repeat((4 - (base64Url.length % 4)) % 4); // Add padding
      const decodedPayload = atob(base64); // Decode the Base64 string
      // Parse the decoded payload as JSON
      const parsedPayload = JSON.parse(decodedPayload);
      // Return the expiration time if available
      if (parsedPayload.exp) {
        return parsedPayload.exp;
      }
    } catch (error) {
      console.error('Error parsing token payload:', error);
    }

    return null;
  }

  isTokenExpired(): boolean {
    const tokenExpiration = this.getTokenExpiration(this.getAccessToken());
    const currentTime = Date.now() / 1000;
    return tokenExpiration && currentTime > tokenExpiration ? true : false;
  }

  // public refreshToken() {
  //   return this.http
  //     .post<{ refresh: string }>(`${environment.apiUrl}/token/refresh/`, {
  //       refresh: this.
  //     })
  //     .pipe(
  //       tap((creadential: { access: string; refresh: string }) => {
  //         const jwt = jwtDecode<UserDecoded>(creadential.access);
  //         this.tokenService.setAccessToken(creadential.access);
  //         this.setSession(jwt);
  //       }),
  //     );
  // }
}
