import { jwtDecode } from 'jwt-decode';
import { tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { TokenService } from '../../core/services/token.service';
import { SnackBarService } from '../../shared/components/snack-bar/snack-bar.service';

interface LoginResponse {
  access: string;
  refresh: string;
}

export interface UserDecoded {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
  email: string;
  name: string;
  last_name: string;
  is_superuser: boolean;
}

export interface Credentials {
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isSignIn = signal(false);
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private snackbarService: SnackBarService,
    private router: Router,
  ) {}

  private setSession(authResult: UserDecoded) {
    localStorage.setItem('user', JSON.stringify(authResult));
  }

  updateSession(fields: Partial<UserDecoded>) {
    const authResult = { ...this.getUser(), ...fields };
    localStorage.setItem('user', JSON.stringify(authResult));
  }

  public signin(credentials: Credentials) {
    return this.http
      .post<LoginResponse>(`${environment.apiUrl}/access/login/`, {
        ...credentials,
      })
      .pipe(
        tap((creadential: { access: string; refresh: string }) => {
          const jwt = jwtDecode<UserDecoded>(creadential.access);
          this.tokenService.setAccessToken(creadential.access);
          this.tokenService.setRefreshToken(creadential.refresh);
          this.setSession(jwt);
          this.isSignIn.set(true);
        }),
      );
  }

  public forgotPassword(email: string) {
    return this.http.post<unknown>(`${environment.apiUrl}/access/resend-password/`, {
      email,
    });
  }

  public resetPassword(password: string, resetPasswordToken: string) {
    return this.http.put<unknown>(
      `${environment.apiUrl}/access/reset-password/?token=${resetPasswordToken}`,
      {
        password,
      },
    );
  }

  public validateUser(
    firstName: string,
    lastName: string,
    currentPassword: string,
    newPassword: string,
    token: string,
  ) {
    return this.http.put<unknown>(`${environment.apiUrl}/access/adm-activation/?token=${token}`, {
      name: firstName,
      last_name: lastName,
      old_password: currentPassword,
      password: newPassword,
    });
  }

  public refreshToken(refreshToken: string) {
    return this.http.post<{ access: string }>(`${environment.apiUrl}/access/refresh/`, {
      refresh: refreshToken,
    });
  }

  public getUser(): UserDecoded | null {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  }

  public isAdmin(): boolean {
    const user = this.getUser();
    if (!user) return false;
    //return user.is_superuser; TODO: disable this for now
    return true;
  }

  public logout() {
    //TODO: create a service this.tokenService.clearToken();
    localStorage.clear();
    localStorage.removeItem('user');
    this.isSignIn.set(false);
    this.router.navigate(['/auth/signin']);
    this.snackbarService.showSnackbar('Saiu', 'Você foi deslogado com sucesso!', 3000);
  }
}
